html {
  background-color: #fff;
}

#hd,
#progress,
#trc-show-hide-sidebar,
#trc-sidebar,
#zenbox_tab,
.benefits-style-button,
.trc-available-products-link,
#trc-sales-event-details img,
.yui-accordionview .indicator,
.trc-level-benefits i,
.swat-toolbar,
.noprint {
  display: none !important;
}

#bd {

  .main-content {
    padding: 0;
  }
}

.yui-t6 #yui-main,
.yui-t6 #yui-main .yui-b {
  margin-right: inherit;
}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ol {

  li {
    margin-left: 1.5em;
    list-style: decimal;

    &.no-items {
      list-style-type: none;
    }
  }
}

ul.bulletted-list {

  li {
    margin-left: 1.5em;
    list-style-position: inside;
    list-style-type: disc;
  }
}

/* Accordions */

.yui-accordionview {
  border-top: solid 1px #ccc;
  margin-bottom: 1rem;
}

.yui-accordion-panel {
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;

  > a {
    display: block;
    margin-bottom: .5em;
    font-size: 1.2em;
    color: #000;
    line-height: 1.2;
    border: 0;
    background: none !important;
  }

  .yui-accordion-content {
    padding: 0;
  }
}

/* Buttons */

.trc-button {
  text-align: center;
  text-transform: uppercase;
  border: solid 1px #ccc;
  box-shadow: none;
  background: none !important;

  > a,
  > span {
    color: #000;
  }
}

/**
 * Sales event
 */

#trc-sales-campaign-info {
  display: block;
  font-weight: bold;
  font-size: .9em;
  margin-bottom: .5rem;
  line-height: 1.2;
}

#trc-sales-event-info {

  p {
    font-size: 1em;
  }
}

#trc-sales-event-details {

  .trc-sales-details {

    li {
      margin-left: 0;
    }
  }
}

.sponsor_title {
  font-weight: bold;
  font-size: 1.4em;
  letter-spacing: -0.5px;
}

.trc-level-benefits {
  margin-left: 0;
}

/**
 * My Contract
 */

#default-campaign-icon {
  width: 30%;
}

.contract-campaign-header {
  width: 65%;

  h1 {
    font-size: 150%;
    line-height: 1.2;
  }
}

#contract-section1-printfooter {
  margin-top: 2rem;
  border-top: 1px dashed #000;
}

.summary {

  #default-campaign-icon {
    float: left;
    width: 20%;
    margin-right: 1rem;
    vertical-align: middle;
  }
}
